<template>
  <!-- <div>大模块靶向诊断测评完成页</div> -->
  <EndTestBackground :testType="1">
    <b-container class="big-test-end">
      <b-row class="pyb-reward-top">
        <b-col>
          <span class="green">{{ userInfo.studentName }}</span
          >同学，恭喜你完成<span class="green"
            >中考英语冲刺沙漠行动{{
              $route.query.stageId == 3 ? "效果测评" : "靶向诊断"
            }}！</span
          >
        </b-col>
      </b-row>
      <b-row class="pyb-reward">
        <b-col>
          本次测评得分：<span class="green">{{ result.testScore }}分</span>
        </b-col>
      </b-row>
      <b-row class="pyb-reward">
        <b-col>
          获得 测评答题
          <span class="green"
            ><pybWord :pybNum="result.answerRewardNumber"></pybWord
          ></span>
          关卡奖励
          <span class="green"
            ><pybWord :pybNum="result.sprintRewardNumber"></pybWord></span
          >，已纳入你的后勤补给！
        </b-col>
      </b-row>
      <b-row v-if="$route.query.stageId == 3" class="pyb-reward">
        <b-col>
          <span class="green">抢滩登陆</span>关卡已自动解锁，欢迎挑战！
        </b-col>
      </b-row>
      <div v-else>
        <b-row class="big-end-form">
          <span>各种题型定级冲刺难度如下：</span>
        </b-row>
        <b-row
          v-for="(item, index) in sectionDifficulties"
          :key="index"
          class="big-end-form-bottom"
        >
          <b-col class="big-end-form-left">
            <span>{{ getQuestionType(item.sectionType) }}</span>
          </b-col>
          <b-col class="big-end-form-right">
            <span>{{ item.difficulty }}</span>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col class="text-right"
          ><GlobalButton
            buttonType="round"
            buttonText="继续闯关"
            class="big-end-form-btn"
            @afterClick="continueSprint"
          ></GlobalButton
        ></b-col>
        <b-col class="big-end-form-btn-right">
          <span @click="viewReport">查看报告</span>
        </b-col>
      </b-row>
    </b-container>
  </EndTestBackground>
</template>

<script>
import EndTestBackground from "@/components/backgroung/end-test-background.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import { getEngFinishProgress } from "../../api/english-big";
import { mapState } from "vuex";
export default {
  data() {
    return {
      result: "",
      sectionDifficulties: [],
    };
  },
  components: {
    EndTestBackground,
    pybWord,
    GlobalButton,
  },
  mounted() {
    this.finishPage();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    finishPage() {
      const parmeter = { recordExamId: this.$route.query.studentExamId };
      getEngFinishProgress(parmeter).then((res) => {
        console.log("完成页", res);
        this.result = res.data.result;
        this.sectionDifficulties = res.data.result.sectionDifficulties;
      });
    },
    continueSprint() {
      if (this.$route.query.stageId == 3) {
        this.$router.replace({
          name: "HomeIndex",
        });
      } else {
        this.$router.replace({
          name: "BigModuleTrainingBase",
          query: {
            // module: 2,
            studentExamId: this.$route.query.studentExamId,
            sprintId: this.$route.query.sprintId,
          },
        });
      }
    },
    viewReport() {
      this.$router.replace({
        name: "BigModuleReport",
        query: {
          // module: 6, //报告页面背景
          studentExamId: this.$route.query.studentExamId,
          sprintId: this.$route.query.sprintId,
          text: 1,
          stageId: this.$route.query.stageId || this.$route.query.stage,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.big-test-end {
  min-height: 320px;
}
.green {
  color: $text-green;
}
.pyb-reward {
  color: $text-light-gray;
  font-weight: bold;
  margin-bottom: 2%;
  font-size: 1.2rem;
}
.pyb-reward-top {
  color: $text-light-gray;
  font-weight: bold;
  margin-bottom: 5%;
  font-size: 1.2rem;
}
.big-end-form {
  border: 1px solid #e5e5e5;
  // width: 35%;
  margin: 0 auto;
  background-color: #2cb774;
  color: #fff;
  text-align: left;
  margin-top: 5%;
  padding: 5px;
}
.big-end-form-bottom {
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  // width: 35%;
  margin: 0 auto;
  // font-size: 1rem;
  font-weight: 400;
  color: $text-light-gray;
}
.big-end-form-left {
  height: 100%;
  border-right: 1px solid #e5e5e5;
  padding: 5px;
}
.big-end-form-right {
  height: 100%;
  padding: 5px;
}
.big-end-form-btn {
  margin-top: 20px;
}
.big-end-form-btn-right {
  margin-top: 28px;
  text-align: left;
  color: $text-light-gray;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>